import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {AuthService} from './auth.service';
import {ChangeEmailForm} from '../core/models/ChangeEmailForm';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Pageable} from '../core/models/Pageable';
import {StoreData} from '../core/models/StoreData';
import {environment} from 'src/environments/environment';
import {CommissionRequest, CommissionResponse} from '../core/models/PaymentsConfigurations/CommissionConfig';
import {MobbexCredentialRequest} from '../core/models/MobbexCredentialRequest';
import {StoreOutputShortForAdmin} from "../core/models/StoreOutputShortForAdmin";
import {VerifyStoreRequest} from "../core/models/VerifyStoreRequest";
import {Tenant} from "../core/models/Tenant";
import { UpdateStoreTaxIdentifier } from '../core/models/UpdateStoreTaxIdentifier';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  constructor(private httpC: HttpClient, private authSrv: AuthService) {
  }


  validCuit(cuit) {
    console.log('entro, cuit', cuit);
    if (cuit.toString().length !== 11) {      
      return false;
      
    }
    let firstTwoDigits = cuit.toString().substring(0,2);
    const firstTwoOk = ['20','23', '24', '25', '26', '27', '30', '33', '34'].includes(firstTwoDigits);
    
    let middleNineDigits = cuit.toString().substring(2,10);
    const onlyContainsNumbers = (str) => /^\d+$/.test(str);
    const middleNineOk = onlyContainsNumbers(middleNineDigits);
    return firstTwoOk && middleNineOk;

  }


  public getStores(tenantUUID: string, pageNumber?: number, pageSize?: number, multiSortFields?: any[], globalFilter?: string): Observable<Pageable> {
    const httpHeaders = new HttpHeaders().set('Accept', 'application/json');
    let httpParams = new HttpParams();
    let sortFields = {sortFieldsWithOrder: []};
    if (!multiSortFields || multiSortFields.length < 1) {
      const initialSortField = 'idStore:ASC';
      sortFields.sortFieldsWithOrder.push(`${initialSortField}`);
    } else {
      let i = 0;
      while (i < multiSortFields.length) {
        let nameField = multiSortFields[i]['name'];
        let order = multiSortFields[i]['order'] > 0 ? 'ASC' : 'DESC';
        sortFields.sortFieldsWithOrder.push(`${nameField}:${order}`);
        i++;
      }
    }
    const pageNro = pageNumber ? pageNumber : 0;
    httpParams = httpParams.set('pageNumber', pageNro?.toString());
    httpParams = httpParams.set('size', pageSize?.toString());
    httpParams = httpParams.set('sortFields', sortFields.sortFieldsWithOrder.join(','));
    httpParams = httpParams.set('search', globalFilter);

    return this.httpC.get<Pageable>(
      environment.apiAGUrl + `/admin/stores/${tenantUUID}`,
      {headers: httpHeaders, params: httpParams}
    );
  }

  public getStoresShort(tenantUUID: string): Observable<StoreOutputShortForAdmin[]> {
    return this.httpC.get<StoreOutputShortForAdmin[]>(environment.apiAGUrl + `/admin/stores/short-list/${tenantUUID}`);
  }

  /**
   * @deprecated The method should not be used
   */
  public verifyStore(idStore: number, verified: string): Observable<void> {
    return this.httpC.patch<void>(
      environment.apiAGUrl + `/store/habilitate/${idStore}/${verified}`,
      null
    );
  }

  public verifyStoreWithTenant(idStore: number, verifyStoreRequest: VerifyStoreRequest): Observable<void> {
    return this.httpC.patch<void>(
      environment.apiAGUrl + `/store/verify/${idStore}`,
      verifyStoreRequest
    );
  }

  public getStoreById(idStore: number): Observable<StoreData> {
    return this.httpC.get<StoreData>(
      `${environment.apiAGUrl}/stores/by_id/${idStore}`
    );
  }

  public changeEmail(form: ChangeEmailForm): Observable<any> {
    return this.httpC.patch<any>(
      `${environment.apiAGUrl}/admin/store/changeEmail`,
      form
    );
  }

  public changeCuit(form: UpdateStoreTaxIdentifier, idStore: number): Observable<void> {
    return this.httpC.patch<void>(
      `${environment.apiAGUrl}/admin/store/update/${idStore}`,
      form
    );
  }

  public getCommissionConfig(storeId: number): Observable<CommissionResponse> {
    return this.httpC.get<CommissionResponse>(environment.apiAGUrl + `/payments/commission/${storeId}`);
  }

  public setCommissionConfig(storeId: number, payload: CommissionRequest): Observable<void> {
    return this.httpC.patch<void>(environment.apiAGUrl + `/payments/commission/${storeId}`, payload);
  }

  public deleteStore(storeId: number): Observable<void> {
    return this.httpC.patch<void>(
      `${environment.apiAGUrl}/admin/store/delete/` + storeId,
      null
    );
  }

  public unlockStore(storeId: number): Observable<void> {
    return this.httpC.post<void>(
      `${environment.apiAGUrl}/stores/unlock-login/` + storeId,
      null
    );
  }

  public getTenantList(): Observable<Tenant[]> {
    return this.httpC.get<Tenant[]>(
      `${environment.apiAGUrl}/stores/tenants`
    );
  }
}
