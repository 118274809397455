import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, LazyLoadEvent, MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {PaginationService} from 'src/app/services/pagination.service';
import {Router} from '@angular/router';
import {SpinnerService} from 'src/app/services/spinner.service';
import {Store} from 'src/app/core/models/Store';
import {StoreService} from 'src/app/services/store.service';
import {Subscription} from 'rxjs';
import {Table} from 'primeng/table';
import {UserService} from 'src/app/services/user.service';
import {environment} from 'src/environments/environment';
import {Pageable} from "../../../core/models/Pageable";
import {AuthService} from "../../../services/auth.service";
import {Tenant} from "../../../core/models/Tenant";

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss'],
  providers: [MessageService, DialogService],
})
export class StoresComponent implements OnInit, OnDestroy {
  loading = true;
  @ViewChild('dt') table: Table;
  stores: Store[];
  fieldsFilter: Array<string> = [
    'name',
    'taxIdentifier',
    'address',
    'email',
    'city',
    'phone',
  ];
  subs: Subscription[] = [];
  isSuperAdmin: boolean;
  isReader: boolean;
  isWriter: boolean;
  showOpt = false;
  showOptId: number;
  refresh: boolean;
  eventTable = null;
  totalRecords: number;
  pageSize: number = 50;
  pageNumber: number = 0;
  maxLengthEllipsis = 13;
  tenantList: Tenant[] = [];
  tenantUUIDSelected: string;
  tenantSelected: Tenant = null;
  searchTerm = null;

  constructor(
    private storeSrv: StoreService,
    private confirmationSrv: ConfirmationService,
    private dialogSrv: DialogService,
    private messageSrv: MessageService,
    private spinnerSrv: SpinnerService,
    private router: Router,
    private authSrv: AuthService,
    private userSrv: UserService,
    private paginationSrv: PaginationService
  ) {
  }

  ngOnInit(): void {    
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    this.isReader = this.userSrv.isReader();
    this.isWriter = this.userSrv.isWriter();
    sessionStorage.removeItem('storeId');
    if (this.isSuperAdmin) {      
      this.getTenantList();
    }
    this.tenantUUIDSelected = localStorage.getItem('tenant');
  }

  alertNotAuthorized(): void {
    this.confirmationSrv.confirm({
      message:
        'Lo sentimos, no dispones de los permisos para ver esta información.',
      rejectVisible: false,
      key: 'notAuthorized',
      icon: 'fa-light fa-warning text-yellow text-12xl',
      acceptButtonStyleClass: 'btn btn__primary',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        this.back();
      },
    });
  }

  getUrlImage(url: string): string {
    return `${environment.mediaUrl}${url}`;
  }

  onRefresh(): void {
    this.loadStores(this.eventTable);
    this.getTenantList();
    this.refresh = true;
    setTimeout(() => {
      this.refresh = false;
    }, 2000);
  }

  onShowOpt(storeId): void {
    if (this.showOptId !== storeId) {
      this.showOptId = storeId;
      this.showOpt = true;
    } else {
      this.showOpt = !this.showOpt;
    }
  }

  back(): void {
    this.router.navigate(['home']);
  }

  loadStores(e: LazyLoadEvent): void {
    if (!this.tenantUUIDSelected || (this.tenantUUIDSelected === "undefined")) {      
      return; 
     }
    if (this.isReader === true || this.isSuperAdmin) {
      this.spinnerSrv.loadSpinner.next(true);
      this.eventTable = e;
      this.searchTerm = e.globalFilter;
      this.pageNumber = e.first / e.rows;
      this.pageSize = e.rows;
      const multiSortFields = this.paginationSrv.getMultiSortObject(e);
      this.subs.push(
        this.storeSrv.getStores(this.tenantUUIDSelected, this.pageNumber, this.pageSize, multiSortFields, e.globalFilter).subscribe({
          next: (data: Pageable) => {
            this.stores = data.content;
            this.totalRecords = data.totalElements;
            this.loading = false;
            this.spinnerSrv.loadSpinner.next(false);
          },
          error: () => {
            this.loading = false;
            this.spinnerSrv.loadSpinner.next(false);
          }
        })
      );
    } else {
      this.alertNotAuthorized();
    }
  }

  getTenantList() {
    this.storeSrv.getTenantList().subscribe({
      next: (data: Tenant[]) => {
        this.tenantList = data;
        this.addUnassignedMockTenant();
        if (sessionStorage.getItem('tenant-stores')) {
          this.tenantUUIDSelected = sessionStorage.getItem('tenant-stores');
        } else {
          this.tenantUUIDSelected = this.tenantList[0].uuid;
        }
        const ev = {first:0, rows:50, sortOrder:1};        
        this.loadStores(ev);
      }
    });
  }

  addUnassignedMockTenant() {    
    const mockTenant = new Tenant();
    mockTenant.id = -1;
    mockTenant.name = "SIN ASIGNAR";
    mockTenant.uuid = "unassigned";
    mockTenant.isAvailable = true;
    mockTenant.storeOwner = null;
    this.tenantList.push(mockTenant);
  }

  changeTenant(event) {
    this.tenantUUIDSelected = event.value;    
    this.tenantSelected = this.tenantList.find(te => te.uuid === this.tenantUUIDSelected);    
    sessionStorage.removeItem('stores-table');
    this.table.reset();
    this.searchTerm = null;
    sessionStorage.setItem('tenant-stores', event.value);
    this.loadStores(this.eventTable);
  }

  getUrl(logoBase64: string): string {
    return 'data:image/jpg;base64,' + logoBase64;
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  detailStore(store) {
    this.router.navigate(['store', store.idStore]);
  }
}
