import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators,} from '@angular/forms';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfirmationService, Message} from 'primeng/api';

import {Admin} from 'src/app/core/models/Admin';
import {FormService} from 'src/app/services/form.service';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {RequestErrorTriggerService} from 'src/app/services/request-error-trigger.service';
import {Router} from '@angular/router';
import {SpinnerService} from 'src/app/services/spinner.service';
import {Subscription} from 'rxjs';
import {UserService} from 'src/app/services/user.service';
import {environment} from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';
import { Tenant } from 'src/app/core/models/Tenant';

@Component({
  selector: 'app-administrators',
  templateUrl: './administrators.component.html',
  styleUrls: ['./administrators.component.scss'],
})
export class AdministratorsComponent implements OnInit, OnDestroy {
  editId: number;
  isEditing = false;
  adminForm: UntypedFormGroup;
  subs: Subscription[] = [];
  admins: Admin[];
  headerPopup: string;
  adminDialog: boolean;
  submitted: boolean;
  errorMessage: Array<Message> = [];
  loading: boolean;
  fieldsFilter: Array<string> = ['name', 'lastName', 'username', 'tenant', 'email'];
  maxPeopleNameLength: number;
  maxPeopleLastNameLength: number;
  maxUsernameLength: number;
  minPasswordLength: number;
  maxPasswordLength: number;
  formHasBeenSubmitted: boolean = false;
  refresh: boolean;
  formHelper: FormService;
  isSuperAdmin: any;
  tenants: any;
  tenantList: Tenant[];
  tenantUUIDSelected: string;

  constructor(
    private router: Router,
    private spinnerSrv: SpinnerService,
    private userSrv: UserService,
    private formBuilder: UntypedFormBuilder,
    private errorService: RequestErrorTriggerService,
    private confirmationService: ConfirmationService,
    private validateInputService: InputValidationService,
    private formService: FormService,
    private authSrv: AuthService,
    private storeSrv: StoreService,
  ) {
    this.formHelper = this.formService;
    this.maxPeopleNameLength = environment.maxPeopleNameLength;
    this.maxPeopleLastNameLength = environment.maxPeopleLastNameLength;
    this.maxUsernameLength = environment.maxUsernameLength;
    this.minPasswordLength = environment.minPasswordLength;
    this.maxPasswordLength = environment.maxPasswordLength;
    this.adminForm = this.formBuilder.group({
      name: [
        '',
        [Validators.required, Validators.maxLength(this.maxPeopleNameLength)],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.maxLength(this.maxPeopleLastNameLength),
        ],
      ],
      username: [
        '',
        [Validators.required, Validators.maxLength(this.maxUsernameLength)],
      ],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.maxLength(this.maxPasswordLength), Validators.minLength(this.minPasswordLength)]],
      firstEntry: [false],
      reader: [false],
      writer: [false],
      tenant: ['', [Validators.required]],
    });
  }  

  ngOnInit(): void {
    this.loadAdmins();
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    if (this.isSuperAdmin) {      
      this.getTenantList();
    }
  }
  

  getTenantList() {
    this.storeSrv.getTenantList().subscribe({
      next: (data: Tenant[]) => {
        this.tenantList = data;
      }
    });
  }

  back(): void {
    this.router.navigate(['home']);
  }

  refreshAdmins(): void {
    this.loadAdmins();
    this.refresh = true;
    setTimeout(() => {
      this.refresh = false;
    }, 2000);
  }

  loadAdmins(): void {
    this.spinnerSrv.loadSpinner.next(true);
    this.subs.push(
      this.userSrv.getAdmins().subscribe({
        next: data => {
          this.admins = data;
          this.spinnerSrv.loadSpinner.next(false);
        },
        error: err => {
          this.spinnerSrv.loadSpinner.next(false);
        }
      })
    );
  }

  editAdmin(admin: Admin): void {
    this.editId = admin.id_admin;
    this.isEditing = true;
    this.headerPopup = 'Editar administrador';
    const {
      name,
      lastName,
      username,
      email,
      password,
      firstEntry,
      reader,
      writer,
    } = admin;
    this.adminForm.patchValue({
      name,
      lastName,
      username,
      email,
      password,
      firstEntry,
      reader,
      writer,
    });
    this.tenantField.patchValue(admin.tenantUUID);
    this.passwordField.patchValue("MOCK1234");
    this.submitted = false;
    this.adminDialog = true;
  }

  checkReader(event): void {
  }

  checkWriter(event): void {
  }

  hideDialog(): void {
    this.adminDialog = false;
    this.submitted = false;
    this.isEditing = false;
    this.errorMessage = [];
  }

  openNew(): void {
    this.headerPopup = 'Nuevo administrador';
    this.isEditing = false;
    this.adminForm.reset();
    this.submitted = false;
    this.adminDialog = true;
  }

  deleteAdmin(admin: Admin): void {
    this.confirmationService.confirm({
      message:
        '<span class="topheader no-subheader">¿Deseas eliminar el administrador seleccionado? </span>',
      icon: 'fa-light fa-warning text-yellow text-12xl',
      key: 'confirmDelete',
      acceptLabel: 'Borrar',
      rejectLabel: 'Cancelar',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        this.subs.push(
          this.userSrv.deleteAdmin(admin.id_admin).subscribe({
            next: () => {
              this.loadAdmins();
            },
            error: (err) => {
              if (err.status === 400 || err.status === 404) {
                this.handleErrorMessage(err.status, 'attribute');
              }
            }
          })
        );
      },
    });
  }

  submitAdmin(): void {
    this.submitted = true;
    this.formHasBeenSubmitted = true;

    if (this.adminForm.valid) {
      this.spinnerSrv.loadSpinner.next(true);

      if (!this.isEditing) {
        this.subs.push(
          this.userSrv.createAdmin(this.adminForm.value).subscribe({
            next: id => {
              this.loadAdmins();
              this.hideDialog();
              this.formHasBeenSubmitted = false;
              this.spinnerSrv.loadSpinner.next(false);
            },
            error: err => {
              if (err.status === 409) {
                this.handleErrorMessage(err.status, 'admin');
              }
            }
          })
        );
      } else {
        this.subs.push(
          this.userSrv.editAdmin(this.editId, this.adminForm.value).subscribe({
            next: () => {
              this.loadAdmins();
              this.hideDialog();
              this.isEditing = false;
              this.formHasBeenSubmitted = false;
              this.spinnerSrv.loadSpinner.next(false);
            },
            error: err => {
              if (err.status === 400 || err.status === 404) {
                this.handleErrorMessage(err.status, 'admin');
              }
            }
          })
        );
      }
    }
  }

  onCloseModal() {
    this.formHasBeenSubmitted = false;
    this.adminDialog = false;
  }

  private handleErrorMessage(status, type): void {
    let message: string;
    if (type === 'admin') {
      switch (status) {
        case 400:
          message = 'Administrador no válido.';
          break;
        case 404:
          message = 'Administrador inexistente.';
          break;
        case 409:
          message = 'El Email ya existe para otro usuario.';
          break;
      }
    }
    this.errorService.updateShowError({
      showError: true,
      message,
    });
  }

  get nameValueField(): AbstractControl {
    return this.adminForm.get('name');
  }

  get lastNameValueField(): AbstractControl {
    return this.adminForm.get('lastName');
  }

  get usernameValueField(): AbstractControl {
    return this.adminForm.get('username');
  }

  get passwordField(): AbstractControl {
    return this.adminForm.get('password');
  }

  get tenantField(): AbstractControl {
    return this.adminForm.controls.tenant;
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }
}
