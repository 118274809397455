<div class="stores">
  <div class="wrapper">
    <p-toast></p-toast>

    <div class="head">
      <div class="text-base lh-24 text-primary font-semibold flex align-items-center cursor-pointer" (click)="back()">
        <i class="fa-xl fa-regular fa-arrow-left text-primary mr-2"></i> Volver al inicio
      </div>
      <div *ngIf="isSuperAdmin">
        <p-dropdown [options]="tenantList" [(ngModel)]="tenantUUIDSelected" (onChange)="changeTenant($event)"
                    [placeholder]="tenantUUIDSelected ? tenantSelected?.name : 'Seleccionar Marca'"
                    optionLabel="name" optionValue="uuid"
                    class="dropdown tenant-dropdown"></p-dropdown>
      </div>
    </div>

    <div class="table__container">
      <p-table #dt [value]="stores" [rows]="pageSize" [lazy]="true"
               (onLazyLoad)="loadStores($event)"
               [paginator]="true" stateStorage="session" stateKey="stores-table"
               sortMode="multiple" [totalRecords]="totalRecords" selectionMode="single" [loading]="loading"
               sortField="name"
               sortOrder="1" [rowHover]="true" dataKey="id" [globalFilterFields]="fieldsFilter"
               [rowsPerPageOptions]="[10,25,50,100]" currentPageReportTemplate="Mostrar" [showCurrentPageReport]="true"
               class="table">

        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-between table-header first-line">
            <div class="flex justify-content-start align-items-center">
              <i class="fa-light fa-store fa-lg mr-2"></i>
              <p class="my-0 text-xl ml-3 font-semibold">COMERCIOS REGISTRADOS</p>
              <i class="fa-solid fa-arrows-rotate text-primary cursor-pointer fa-lg ml-3 mr-3"
                 [ngClass]="{ 'spin': refresh }" (click)="onRefresh()"></i>
              <div class="box__total">
                Total <span class="text-primary">{{ totalRecords | number:'1.0-2' }}</span>
              </div>
            </div>
            <div class="search-container">
              <div class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                       placeholder="Buscar por nombre" [(ngModel)]="searchTerm"/>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="idStore" style="width: 4%">
              <div class="flex align-items-center">
                ID
                <p-sortIcon field="idStore"></p-sortIcon>
              </div>
            </th>
            <th style="width: 4%">
            </th>
            <th pSortableColumn="name" style="width: 12%">
              <div class="flex align-items-center">
                Nombre
                <p-sortIcon field="name"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="cuit" style="width: 8%">
              <div class="flex align-items-center">
                Cuit
                <p-sortIcon field="cuit"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="email" style="width: 15%">
              <div class="flex align-items-center">
                Email
                <p-sortIcon field="email"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="city" style="width: 8%">
              <div class="flex align-items-center">
                Ciudad
                <p-sortIcon field="city"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="address" style="width: 10%">
              <div class="flex align-items-center">
                Dirección
                <p-sortIcon field="address"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="accountVerified" style="width: 10%">
              <div class="flex align-items-center">
                Verificación BiD
                <p-sortIcon field="accountVerified"></p-sortIcon>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-store>
          <tr class="table-row" [pContextMenuRow]="store" (click)="detailStore(store)">
            <td>{{ store.idStore }}</td>
            <td>
              <img [src]="getUrlImage(store.logoUrl)" alt="{{store.name}}"/>
            </td>
            <td>{{ store.name }}</td>
            <td>{{ store.taxIdentifier }}</td>
            <td>{{ store.email }}
              <i class="fa-solid fa-check-circle fa text-success margin-left-8" *ngIf="store.enabled"></i>
            </td>
            <td>{{ store.city }}</td>
            <td class="flex align-items-center">
              <div class="address-store">
                {{ store.address }}
              </div>
              <div *ngIf="store.address.length > maxLengthEllipsis" class="icon__detail-address"
                   pTooltip="{{store.address}}" tooltipPosition="top">
                <i class="fa-solid fa-circle-info text-primary"></i>
              </div>
            </td>
            <td>
              <span *ngIf="store.accountVerified && !store.blocked" class="bid-badge bid-badge--green">VERIFICADO</span>
              <span *ngIf="!store.accountVerified && !store.blocked"
                    class="bid-badge bid-badge--orange">SIN VERIFICAR</span>
              <span *ngIf="store.blocked" class="bid-badge bid-badge--red">BLOQUEADO</span>
            </td>
          </tr>
          <div class="my-2"></div>
        </ng-template>
      </p-table>
    </div>
  </div>

  <p-confirmDialog key="confirmVerificacion" [style]="{ width: '450px' }" acceptLabel="Aceptar" rejectLabel="Cancelar"
                   rejectButtonStyleClass="btn btn__secondary btn__secondary--bold"
                   acceptButtonStyleClass="btn btn__primary">
  </p-confirmDialog>

  <p-confirmDialog key="notAuthorized" styleClass="confirm-dialog-custom" [style]="{width: '424px'}" (onHide)="back()"
                   icon="pi pi-exclamation-triangle" styleClass="confirmJwtExpired"
                   acceptLabel="Regresar"></p-confirmDialog>
</div>
