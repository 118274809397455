import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {map} from 'rxjs';
import {CategoryEventResponse} from 'src/app/core/models/CategoryEventResponse ';
import {EventComplete} from 'src/app/core/models/EventComplete';
import {CategoriesEventsService} from 'src/app/services/categories-events.service';
import {EventGuestService} from 'src/app/services/event-guest.service';
import {EventsService} from 'src/app/services/events.service';
import {RequestErrorTriggerService} from 'src/app/services/request-error-trigger.service';
import {SpinnerService} from 'src/app/services/spinner.service';
import * as FileSaver from 'file-saver';
import {EventGuestDataToExport} from 'src/app/core/models/events/EventGuestDataToExport';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {environment} from 'src/environments/environment';
import {TransactionOutputForExport} from "../../../../../core/models/TransactionOutputForExport";
import {TransactionService} from "../../../../../services/transaction.service";
import {ExportTransactionsRequest} from "../../../../../core/models/events/ExportTransactionsRequest";
import {EventStatus} from "../../../../../shared/enums/EventStatus";
import {Location} from "@angular/common";
import {EventSalesStaticsResponse} from "../../../../../core/models/events/EventSalesStaticsResponse";
import {DialogService} from "primeng/dynamicdialog";

@Component({
  selector: 'app-event-configuration',
  templateUrl: './event-configuration.component.html',
  styleUrls: ['./event-configuration.component.scss'],
  providers: [MessageService, DialogService]
})
export class EventConfigurationComponent implements OnInit {

  eventId: string;
  event: EventComplete;
  categoriesEvents: Array<CategoryEventResponse> = [];
  categoriesForEvent: Array<CategoryEventResponse> = [];
  subs = [];
  loading = false;
  downloading = false;
  guestsToExport: Array<EventGuestDataToExport> = [];
  eventCategory: CategoryEventResponse;
  showModalSettlement = false;
  itemsEventMenu: MenuItem[];
  categoryEmpty = {
    idCategory: 0,
    name: 'Seleccioná una categoría',
    position: 0,
    showOnBillboard: false,
    quantityEvents: 0,
    tenant: 0
  };
  headerPopup = 'headerPopup';
  ticketDescriptions = [];
  monthTrxList: TransactionOutputForExport[] = null;
  showEditEventModal = false;
  eventSalesInfo: EventSalesStaticsResponse = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private eventService: EventsService,
    private categoriesEventSrv: CategoriesEventsService,
    private spinnerSrv: SpinnerService,
    private errorService: RequestErrorTriggerService,
    private eventsSrv: EventsService,
    private eventGuestsSrv: EventGuestService,
    private confirmationService: ConfirmationService,
    private transactionSrv: TransactionService,
    private location: Location,
    private messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.spinnerSrv.loadSpinner.next(true);
    this.getEventData();
  }

  getEventData() {
    this.spinnerSrv.loadSpinner.next(true);
    this.activatedRoute.paramMap.pipe(map((params: ParamMap) => params.get('id'))).subscribe({
      next: eventId => {
        this.eventService.getEventCompleteById(parseInt(eventId)).subscribe({
          next: (res: EventComplete) => {
            this.event = res;
            this.getEventSalesInfo();
            this.generateEventMenu();
            this.categoriesEventSrv.getCategoriesEventByTenant(this.event.storeResponse.tenantResponse.uuid).subscribe({
              next: async (categories: CategoryEventResponse[]) => {
                this.categoriesEvents = categories;
                await this.getCategoryEvent(this.event, categories);
              }
            })
          },
          error: (err) => {
            this.spinnerSrv.loadSpinner.next(false);
            this.handleErrorMessage(err.status);
          }
        });
      }
    })
  }

  getEventSalesInfo() {
    this.eventService.getEventStatics(this.event.idEvent).subscribe({
      next: (res: EventSalesStaticsResponse) => {
        this.eventSalesInfo = res;
        this.spinnerSrv.loadSpinner.next(false);
      },
      error: (err) => {
        this.spinnerSrv.loadSpinner.next(false);
      }
    });
  }

  generateEventMenu() {
    //add delete-item class for red font
    this.itemsEventMenu = [
      {
        label: 'Generar liquidación',
        icon: 'fa-light fa-file-invoice-dollar',
        disabled: !this.eventService.IsEventOver(this.event) || this.event.status !== EventStatus.LIQUIDATED,
        command: () => this.confirmSettlement(),
      },
      {
        label: 'Reprogramar evento',
        icon: 'fa-light fa-clock-rotate-left',
        styleClass: null,
        disabled: this.event.status === EventStatus.LIQUIDATED || this.event.status === EventStatus.IN_PROGRESS,
        command: () => this.rescheduleEvent(),
      },
      {
        label: 'Exportar transacciones',
        icon: 'fa-light fa-download',
        styleClass: null,
        command: () => this.downloadExcelTransactions(),
      },
      {
        label: 'Cancelar evento',
        icon: 'fa-light fa-trash-can delete-item',
        styleClass: 'delete-item',
        disabled: this.event.status !== EventStatus.PENDING && this.event.status !== EventStatus.IN_PROGRESS,
        command: () => this.cancelEvent(),
      }];
  }

  backToEvent() {
    this.location.back();
  }

  downloadExcelTransactions() {
    this.loading = true;
    this.spinnerSrv.loadSpinner.next(true);
    const exportTransactionsRequest = new ExportTransactionsRequest();
    exportTransactionsRequest.eventId = this.event.idEvent;
    exportTransactionsRequest.storeId = this.event.storeResponse.id;
    exportTransactionsRequest.tenantUUID = this.event.storeResponse.tenantResponse.uuid;
    this.subs.push(
      this.transactionSrv.getTransactionsByEventId(exportTransactionsRequest).subscribe({
        next: (res: TransactionOutputForExport[]) => {
          this.monthTrxList = res;
          this.exportExcel('Transacciones-' + this.event.eventName, this.monthTrxList);
          this.spinnerSrv.loadSpinner.next(false);
          this.loading = false;
        },
        error: () => {
          this.spinnerSrv.loadSpinner.next(false);
          this.loading = false;
        }
      })
    );
  }

  // Export to excel functions
  downloadExcel() {
    this.downloading = true;
    this.spinnerSrv.loadSpinner.next(true);
    this.eventGuestsSrv.getDataGuestsToExport(this.event.idEvent).subscribe({
      next: (res: EventGuestDataToExport[]) => {
        this.guestsToExport = res;
        this.exportExcel('Invitados-' + this.event.eventName, this.guestsToExport);
        this.spinnerSrv.loadSpinner.next(false);
        this.downloading = false;
      },
      error: () => {
        this.spinnerSrv.loadSpinner.next(false);
        this.downloading = false;
      }
    });
  }

  exportExcel(nameFile: string, listToExport: any) {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(listToExport);
      const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']};
      const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      this.saveAsExcelFile(excelBuffer, nameFile);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  async getCategoryEvent(event: EventComplete, categories: CategoryEventResponse[]) {
    if (event.categoryId === null) {
      this.eventCategory = this.categoryEmpty;
    } else {
      this.eventCategory = await categories.find((c: CategoryEventResponse) => c.idCategory === event.categoryId);
    }
  }

  cancelEvent() {
    this.confirmationService.confirm({
      message:
        '¿Estás seguro de cancelar este evento?',
      header: '',
      icon: 'icon-warning icon-4xl icon-yellow',
      key: 'settlement',
      rejectIcon: 'none',
      acceptIcon: 'none',
      acceptButtonStyleClass: 'btn btn__primary',
      acceptLabel: 'Cancelar evento',
      rejectLabel: 'Volver',
      rejectButtonStyleClass: 'btn btn__secondary',
      accept: async () => {
        this.spinnerSrv.loadSpinner.next(true);
        this.eventService.cancelEvent(this.event.idEvent).subscribe({
          next: () => {
            this.getEventData();
          },
          error: (err) => {
            this.spinnerSrv.loadSpinner.next(false);
            this.handleErrorMessage(err.status);
          }
        });
      },
      reject: () => {
      },
    });
  }

  confirmSettlement() {
    this.confirmationService.confirm({
      message:
        'Si liquidas este evento no se podrán realizar reembolsos.',
      header: '',
      icon: 'icon-warning icon-4xl icon-yellow',
      key: 'settlement',
      rejectIcon: 'none',
      acceptIcon: 'none',
      acceptButtonStyleClass: 'btn btn__primary',
      acceptLabel: 'Liquidar evento',
      rejectLabel: 'Cancelar',
      rejectButtonStyleClass: 'btn btn__secondary',
      accept: async () => {
        this.spinnerSrv.loadSpinner.next(true);
        this.eventService.liquidateEvent(this.event.idEvent).subscribe({
          next: () => {
            this.getEventData();
          },
          error: (err) => {
            this.spinnerSrv.loadSpinner.next(false);
            this.handleErrorMessage(err.status);
          }
        });
      },
      reject: () => {
      },
    });
  }

  rescheduleEvent() {
    this.showEditEventModal = true;
  }

  onCloseRescheduleEventModal(e) {
    this.showEditEventModal = false;
    if (e === true) {
      this.getEventData();
      this.messageService.add({
        sticky: false,
        closable: false,
        severity: 'success',
        summary: 'Evento reprogramado con éxito.',
        detail: '',
        life: 5000
      })
    }
  }

  onRefreshEventData() {
    this.getEventData();
  }

  updateEvent() {
    this.eventService.getEventCompleteById(this.event.idEvent).subscribe({
      next: (res: EventComplete) => {
        this.event = res;
      }
    })
  }

  private handleErrorMessage(status): void {
    let message: string;
    switch (status) {
      case 404:
        message = 'Evento no encontrado.';
        break;
      case 409:
        message = 'El evento ya ha finalizado.';
        break;
    }
    this.errorService.updateShowError({
      showError: true,
      message,
    });
  }

  onChangeCategory(idCategory: number) {
    let category: CategoryEventResponse = this.categoriesEvents.find((cat: CategoryEventResponse) => {
      return cat.idCategory === idCategory
    })
    if (idCategory === null || idCategory === undefined) {
      category = new CategoryEventResponse();
    }
    this.setCategoryEvent(category);
  }

  setCategoryEvent(category: CategoryEventResponse): void {
    this.loading = true;
    this.spinnerSrv.loadSpinner.next(true);
    this.subs.push(
      this.eventsSrv.setCategoryEvent(this.event.idEvent, category).subscribe({
        next: () => {
          this.loading = false;
          this.getEventData();
          this.spinnerSrv.loadSpinner.next(false);
        },
        error: (err) => {
          if (err.status === 404) {
            this.handleErrorMessage(err.status);
          }
        }
      })
    );
  }

  onHide() {
    this.showModalSettlement = false;
  }

  getUrlImage(): string {
    return `${environment.mediaUrl}${this.event.imageUrl}`;
  }

}
